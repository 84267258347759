
  import { ElButton,ElContainer,ElHeader,ElAside,ElMain,ElMessageBox,ElMessage,ElLoading } from 'element-plus'
  import { ArrowDown } from '@element-plus/icons-vue'
  import { defineComponent,provide } from 'vue';
  import { loginOut } from '@/axios/base';
  import { updatePassword,getStoreList } from '@/axios/brand';
  import mitt from '@/js/mitts';
  type companys = {
    id: number,
    store_name: string
  }
  export default defineComponent({
    name: 'setHome',
    data(){
      return {
        companyList: [{id: 0, store_name:'',brand_pk:0}],
        checkCompany: <string|number>(''),
        user: '',
        menuList: [
          {name: '',path:'',child:[{name: '',path:'',child:[{name:'',path:''}]}]}
          // {
          //   name: '系统设置',
          //   child: [
          //     {
          //       name: '平台管理',
          //       child: [
          //         {name:'品牌管理',path: '/brandManager'}
          //       ]
          //     },
          //     {
          //       name: '人员管理',
          //       child: [
          //         {name:'角色管理',path: '/roleManager'},
          //         {name:'员工管理',path: '/staffManager'}
          //       ]
          //     }
          //   ]
          // },
          // {
          //   name:'智慧营销',
          //   child: [
          //     {
          //       name: '小程序营销',
          //       child: [
          //         {name: '营销位管理',path: '/marketManager'},
          //         {name: '小程序换肤',path: '/changeSkin'},
          //         {name: '次卡营销',path: '/numCard'},
          //         {name: '优惠券活动',path: ''}
          //       ]
          //     },
          //     {
          //       name: 'K歌营销',
          //       child: [
          //         {name: 'K歌屏营销位',path: '/songScreen'}
          //       ]
          //     },
          //     {name: '活动案例库'},
          //     {name: '私域通'}
          //   ]
          // },
          // {
          //   name:'智慧移动KTV',
          //   child: [
          //     {name: '服务人员',path: '/servePeo'},
          //     {name: '被动任务管理',path: '/songScreen'}
          //   ]
          // },
          // {name:'智慧决策'}
        ],
        checkMenu: 0,
        reloads: true,
        dialogPass: false,
        form: {
          old_password: '',
          new_password: ''
        }
      }
    },
    components: {
      ElButton,ElContainer, ElHeader, ElAside, ElMain, ArrowDown
    },
    mounted(){
      this.companyList = JSON.parse(sessionStorage.getItem('accountInfo')||'').company_list;
      if(JSON.stringify(sessionStorage.getItem('checkCompany'))){
        this.checkCompany = JSON.parse(sessionStorage.getItem('accountInfo')||'').company_list[sessionStorage.getItem('checkCompany') || 0].id;
      }
      else{
        this.checkCompany = JSON.parse(sessionStorage.getItem('accountInfo')||'').company_list[0].id;
        sessionStorage.setItem('checkCompany','0');
      }
      
      this.menuList = JSON.parse(sessionStorage.getItem('accountInfo')||'').menu_list;
      this.user = JSON.parse(sessionStorage.getItem('accountInfo')||'').name;
      mitt.on('refreshMenu', this.refreMenu)

      //页面刷新，选择顶部块
      let newarr:Array<[]> = [];
      let duceArr = (arr:any) => {
        arr.map((item:any) => {
          newarr.push(item);
          if(item.child && item.child.length>0){
            duceArr(item.child)
          }
        })
      }
      duceArr(this.menuList)
      
      let startpar = 0;
      newarr.some((item:any) => {
        if(item.path == this.$route.path){
          startpar = item.parent_id;
          return true;
        }
      })
      
      let endItem:any = '';
      let isend = () => {
        newarr.some((item:any) => {
          if(item.id == startpar){
            startpar = item.parent_id;
            endItem = item;
            return true;
          }
        })
        if(endItem.parent_id){
          isend();
        }
      }
      isend();
      console.log(endItem);
      this.menuList.some((item:any,index) => {
        if(item.id == endItem.id){
          this.checkMenu = index;
          return true;
        }
      })
      
    },
    methods: {
      toPages: function(path:string){
        if(path.includes('http')){
          window.open(path)
        }
        else{
          this.$router.push(path)
        }
      },
      refreMenu: function(){
        let _this = this;
        getStoreList({
          brand_pk: ''
        }).then((data:any) => {
          _this.companyList = data;
          let info = JSON.parse(sessionStorage.getItem('accountInfo')||'');
          info.company_list = data;
          sessionStorage.setItem('accountInfo',JSON.stringify(info));
        }).catch((err:any) => {})
      },
      clickMenu: function(num:number){
        this.checkMenu = num;

        let newarr:Array<[]> = [];
        let duceArr = (arr:any) => {
          arr.map((item:any) => {
            if(item.child && item.child.length>0){
              duceArr(item.child)
            }
            else{
              newarr.push(item);
            }
          })
        }
        duceArr(this.menuList[num].child)
        newarr.some((item:any) => {
          if(item.path){
            this.$router.push(item.path);
            return true;
          }
        })

        // if(!this.menuList[num].child[0].path){
        //   this.menuList[num].child[0].child.some((item:any) => {
        //     this.$router.push(item.path);
        //     return true;
        //   })  
        // }
        // else{
        //   this.$router.push(this.menuList[num].child[0].path);
        // }
        
      },
      //退出
      quites: function(){
        let _this = this;
        ElMessageBox.confirm('是否退出登陆').then(() => {
          loginOut().then((data:any) => {
            sessionStorage.removeItem('accountInfo');
            _this.$router.push('/login');
          }).catch((err:any) => {})
        }).catch(() => {})
      },
      companyChange: function(e:number){
        this.reloads = false;
        console.log(e);
        // const loading = ElLoading.service({
        //   lock: true,
        //   text: 'Loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // })
        let _this = this;
        this.companyList.some((item,index) => {
          if(item.id == e){
            // loading.close()
            sessionStorage.setItem('checkCompany',index.toString());
            setTimeout(() => {
              _this.$nextTick(() => {
                _this.reloads = true;
              })
            },100)
          }
        })
      },
      queryClick: function(){
        let _this = this;
        // const loading = ElLoading.service({
        //   lock: true,
        //   text: 'Loading',
        //   background: 'rgba(0, 0, 0, 0.7)',
        // })
        updatePassword({
          account: JSON.parse(sessionStorage.getItem('accountInfo') || '').account,
          old_password: this.form.old_password,
          new_password: this.form.new_password
        }).then(data => {
          // loading.close()
          ElMessage('修改成功');
          sessionStorage.removeItem('checkCompany');
          sessionStorage.removeItem('accountInfo');
          _this.$router.push('/login');
        }).catch(err => {
          
        })
      }
    }
  });
